import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import getData from "./functions/getData";

export default function MainNav() {
  const [userData, setUserData] = useState("");
  let history = useHistory();
  function logout() {
    localStorage.removeItem("user_token");
    localStorage.removeItem("param_token");
    localStorage.removeItem("question_page_token");
    localStorage.removeItem("admin_token");
    history.push("/login");
  }
  useEffect(() => {
    if (userData === "") {
      getData().then((x) => {
        if (x.isLoaded) {
          setUserData(x.data);
        }
      });
    }
  }, [userData]);
  return (
    <Navbar fixed="top" bg="light" variant="light">
      <img
        className="d-inline-block align-top mr-3"
        src="https://upload.wikimedia.org/wikipedia/commons/3/3e/Logo_of_Westfield_State_University.svg"
        alt="westfield-pic"
        width="100"
        height="40"
      ></img>
      <Navbar.Brand>
        <span style={{ fontSize: 24, color: "#2196f3" }}>Open </span>
        <strong style={{ fontSize: 24, color: "#064579" }}>Actuary</strong>
      </Navbar.Brand>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>

          <Nav.Link href="/faq">FAQ</Nav.Link>
          <Nav.Link href="/contact">Contact Info</Nav.Link>
        </Nav>
        {userData?.f_name &&
        <Nav className="ml-auto">
          <NavDropdown id="nav-dropdown" alignRight title={userData.f_name}>
            <NavDropdown.Item href="/account-info">
              Account Info
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout}>Sign out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      }
      </Navbar.Collapse>
    </Navbar>
  );
}
