import React, { Component } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import Axios from "axios";
export default class forgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: "",
      alert: false,
      type: "",
      message: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  handleClose() {
    this.setState({
      show: false,
    });
  }
  handleShow() {
    this.setState({
      show: true,
    });
  }
  handleChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  sendEmail(e) {
    e.preventDefault();
    Axios.post(`https://app.openactuary.org/api/forgotPassword`, {
      email: this.state.email,
    }).then((response) => {
      if (response.data.code === 400) {
        console.log(response.data.error);
      } else if (response.data.code === 401) {
        this.setState({
          type: "danger",
          message: response.data.failed,
          alert: true,
        });
      } else if (response.data.code === 200) {
        this.setState({
          type: "success",
          message: response.data.success,
          alert: true,
        });
      }
    });
  }
  render() {
    const { show, email, alert, message, type } = this.state;
    return (
      <>
        <Button
          className="nounderline"
          onClick={this.handleShow}
          variant="link"
        >
          Forgot your password?
        </Button>

        <Modal size="lg" show={show} centered>
          <Modal.Header>
            <Modal.Title>Forgot your Password?</Modal.Title>
            <button onClick={this.handleClose} className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <form onSubmit={this.sendEmail}>
            <Modal.Body>
              Please enter your email address to request a password reset.
              <br />
              <br />
              <div className="center">
                <input
                  type="email"
                  name="email"
                  className="mb-2 col-lg-5 form-control"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mt-2 center">
                <Alert className="col-lg-9" show={alert} variant={type}>
                  <Button
                    variant="link"
                    onClick={() =>
                      this.setState({
                        alert: false,
                      })
                    }
                    className="close nounderline"
                  >
                    <span aria-hidden="true">&times;</span>
                  </Button>
                  <p>{message}</p>
                </Alert>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
