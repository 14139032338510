import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import data from "./data/usStates.json";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      confirmPass: "",
      school: "",
      fName: "",
      lName: "",
      country: "",
      state: "",
      errorMessage: "",
      countries: [],
      states: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getCountries();
  }
  getCountries() {
    axios
      .get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
          Accept: "application/json",
          "api-token": process.env.REACT_APP_COUNTRY,
          "user-email": "openactuary.help@gmail.com",
        },
      })
      .then((response) => {
        axios
          .get("https://www.universal-tutorial.com/api/countries/", {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + response.data.auth_token,
            },
          })
          .then((countries) => {
            this.setState({
              countries: countries.data,
            });
            localStorage.setItem("apiToken", response.data.auth_token);
          });
      });
  }
  getStates() {
    if (this.state.countries !== "") {
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    const { password, confirmPass } = this.state;
    if (password.length < 8) {
      this.setState({
        errorMessage: "Password must be at least 8 characters long",
      });
    } else if (password !== confirmPass) {
      this.setState({
        errorMessage: "Confirmation Password does not match.",
      });
    } else {
      const payload = {
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
        school: this.state.school,
        country: this.state.country,
        state: this.state.state,
      };
      axios
        .post(`https://app.openactuary.org/api/register`, payload)
        .then((response) => {
          if (response.data.code === 200) {
            console.log("registration successfull");
            this.props.history.push("/login");
          } else if (response.data.code === 401) {
            this.setState({
              errorMessage: response.data.failed,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  handleChange(event) {
    let stateArray = [];
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "country") {
      if (event.target.value === "United States") {
        data.map((element, i) => {
          stateArray[i] = { state_name: element.name };
        });
        this.setState({
          states: stateArray,
        });
      } else {
        axios
          .get(
            `https://www.universal-tutorial.com/api/states/${event.target.value}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("apiToken"),
              },
            }
          )
          .then((response) => {
            this.setState({
              states: response.data,
            });
          });
      }
    }
  }
  render() {
    return (
      <div className="sign-in">
        <div className="form-signin">
          <Card
            className="shadow"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
            }}
          >
            <div className="form">
              <span style={{ fontSize: 50, color: "#2196f3" }}>Open </span>
              <strong style={{ fontSize: 50, color: "#064579" }}>
                Actuary
              </strong>
              <h2 className="text-center">REGISTER</h2>
              <h5 className="font-weight-light">
                Please fill in the information below:
              </h5>
              {this.state.errorMessage ? (
                <div style={{ fontSize: 12, fontWeight: "bold", color: "red" }}>
                  {this.state.errorMessage}
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  name="fName"
                  className="mb-2 form-control"
                  placeholder="First Name"
                  value={this.state.fName}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="text"
                  name="lName"
                  className="mb-2 form-control"
                  placeholder="Last Name"
                  value={this.state.lName}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  className="mb-2 form-control"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="text"
                  name="username"
                  className="mb-2 form-control"
                  placeholder="User Name"
                  value={this.state.username}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="password"
                  name="password"
                  className="mb-2 form-control"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="password"
                  name="confirmPass"
                  className="mt-2 form-control"
                  placeholder="Confirm Password"
                  value={this.state.confirmPass}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="text"
                  name="school"
                  className="mt-2 form-control"
                  placeholder="School"
                  value={this.state.school}
                  onChange={this.handleChange}
                  required
                />
                <select
                  type="text"
                  name="country"
                  className="mt-2 form-control"
                  defaultValue=""
                  required
                  onChange={this.handleChange}
                >
                  <option disabled value="">
                    Please choose a Country...
                  </option>
                  {this.state.countries.map((element, i) => (
                    <option key={i}>{element.country_name}</option>
                  ))}
                </select>
                <select
                  type="text"
                  name="state"
                  className="mt-2 form-control"
                  defaultValue=""
                  required
                  onChange={this.handleChange}
                >
                  <option disabled value="">
                    Please choose a State...
                  </option>
                  {this.state.states.map((element, i) => (
                    <option key={i}>{element.state_name}</option>
                  ))}
                </select>
                <Button
                  variant="primary"
                  size="lg"
                  block
                  className="shadow mt-3 mb-1 "
                  type="submit"
                >
                  Create My Account
                </Button>
              </form>
              <Link
                className="nounderline btn btn-lg btn-link btn-block"
                to="/login"
              >
                Back to Login
              </Link>
            </div>
          </Card>
          <br />
        </div>
      </div>
    );
  }
}

export default Register;
