import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import Dynamic from "./display/Dynamic";
import Axios from "axios";
import MainNav from "./MainNav";
import Sidebar from "./sidebar";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionArray: this.props.history.location.state.quesArray,
      type: this.props.history.location.state.type,
      time: this.props.history.location.state.time,
      questionID: 0,
      question: "",
      ansA: "",
      ansB: "",
      ansC: "",
      ansD: "",
      ansE: "",
      selectedOption: "",
      solution: "",
      border: "",
      explanation: "",
      questionIdInArray: 0,
      totalScore: "",
      userData: this.props.location.state.userData,
      mapOfCatTotals: {},
    };

    this.displayQuestion = this.displayQuestion.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  calculateGrade() {
    /**********************Calculate Total Score***********************************/
    let amtCorrect = 0;
    let qArray = this.state.questionArray;
    let numQuestions = qArray.length;
    let tempTotal = "";
    let tempTagTotal = 0;

    let tempTotalScore = function (
      amtCorrect,
      qArray,
      numQuestions,
      tempTotal
    ) {
      for (let i = 0; i < numQuestions; i++) {
        let correctAnswer = qArray[i][6];
        let userAnswer = qArray[i][11];

        if (userAnswer === correctAnswer) {
          amtCorrect = amtCorrect + 1;
        }
      }

      return (tempTotal = amtCorrect + "/" + numQuestions);
    };
    this.setState(
      {
        totalScore: tempTotalScore(amtCorrect, qArray, numQuestions, tempTotal),
      },
      () => {
        if (localStorage.getItem("result_has_run") === null) {
          this.pushResToDatabase();
          localStorage.setItem("result_has_run", true);
        }
      }
    );
    /**************************************************************************/
    /***********************Calculate Individual Tag Scores********************/
    const map = new Map();
    for (let i = 0; i < numQuestions; i++) {
      let tag = qArray[i][8];
      let j = 0;
      switch (tag) {
        case "Probability and Sets":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Conditional Probability":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Bayes’ Theorem":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set("Bayes' Theorem", amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Counting and Combinatorics":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "General Discrete Random Variables":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Hypergeometric Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Moments and Central Tendency":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Geometric Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "General Continuous Random Variables":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Percentiles and Modes":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Poisson Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Discrete Uniform Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Binomial Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Multivariate Random Variables":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Negative Binomial Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Normal Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Exponential Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Continuous Uniform Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Gamma Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Moment Generating Functions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Transformations of Random Variables":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Central Limit Theorem and Chebyshev’s Inequality":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(
              "Central Limit Theorem and Chebyshev's Inequality",
              amtCorrect + "/" + tempTagTotal
            );
          }
          break;
        case "Joint Probability Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Independent Random Variables":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Conditional Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Covariance and Correlation":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Joint Moment Generating Functions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Multivariate Normal Distributions":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Multivariate Transformations":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
        case "Properties of Expectation":
          if (!map.has(tag)) {
            tempTagTotal = 0;
            amtCorrect = 0;
            for (j = 0; j < numQuestions; j++) {
              if (qArray[j][8] === tag) {
                tempTagTotal++;
                if (qArray[j][6] === qArray[j][11]) {
                  amtCorrect++;
                }
              }
            }
            map.set(tag, amtCorrect + "/" + tempTagTotal);
          }
          break;
      }
    }
    this.setState(
      {
        mapOfCatTotals: map,
      },
      () => {
        if (localStorage.getItem("tag_has_run") === null) {
          this.pushTagResToDatabase();
          localStorage.setItem("tag_has_run", true);
        }
      }
    );
  }
  pushTagResToDatabase() {
    const tagMap = this.state.mapOfCatTotals;
    let payload = {};
    for (let [k, v] of tagMap) {
      payload = {
        tagScoreDB: this.props.location.state.tagScoreDB,
        userId: this.state.userData.user_id,
        name: k,
        value: v,
      };
      Axios.post(
        `https://app.openactuary.org/api/insertCatScores`,
        payload
      ).then((response) => {
        if (response.data.code === "200") {
          console.log("success!!");
        } else {
          console.log(response.data.error);
        }
      });
    }
  }

  pushResToDatabase() {
    const payload = {
      resultDB: this.props.location.state.resultDB,
      id: this.state.userData.user_id,
      totalScore: this.state.totalScore,
      timeSpent: this.state.time,
      type: this.state.type,
    };
    Axios.post(`https://app.openactuary.org/api/results`, payload).then(
      (response) => {
        if (response.data.code === "200") {
          console.log(response.data.success);
        } else {
          console.log(response.data.failed);
        }
      }
    );
  }
  componentDidMount() {
    localStorage.removeItem("param_token");
    localStorage.removeItem("time");
    this.calculateGrade();
    this.displayQuestion();
  }
  displayQuestion() {
    const { questionArray, questionIdInArray } = this.state;
    let border = "";
    if (
      questionArray[questionIdInArray][11] ===
      questionArray[questionIdInArray][6]
    ) {
      border = "success";
    } else {
      border = "danger";
    }
    this.setState({
      question: questionArray[questionIdInArray][0],
      ansA: "A) " + questionArray[questionIdInArray][1],
      ansB: "B) " + questionArray[questionIdInArray][2],
      ansC: "C) " + questionArray[questionIdInArray][3],
      ansD: "D) " + questionArray[questionIdInArray][4],
      ansE: "E) " + questionArray[questionIdInArray][5],
      explanation: questionArray[questionIdInArray][9],
      border: border,
      solution: questionArray[questionIdInArray][10],
      selectedOption: questionArray[questionIdInArray][11],
    });
  }

  handleLoad(event) {
    this.displayQuestion();
  }

  goBack() {
    if (this.state.questionIdInArray === 0) {
      this.setState({
        questionIdInArray: this.state.questionIdInArray,
      });
    } else {
      this.setState(
        {
          questionIdInArray: this.state.questionIdInArray - 1,
        },
        () => {
          this.handleLoad();
        }
      );
    }
  }

  goHome() {
    this.props.history.push({
      pathname: `tests/${this.props.location.state.path}`,
    });
    localStorage.removeItem("question_page_token");
  }

  goNext() {
    if (this.state.questionIdInArray === this.state.questionArray.length - 1) {
      this.setState({
        questionIdInArray: this.state.questionIdInArray,
      });
    } else {
      this.setState(
        {
          questionIdInArray: this.state.questionIdInArray + 1,
        },
        () => {
          this.handleLoad();
        }
      );
    }
  }

  render() {
    const { questionArray, questionIdInArray } = this.state;
    return (
      <div class="home">
        <MainNav />
        <Sidebar />
        <div className="questionPage">
          <div class="results-container">
            <header>
              <Button
                className="ml-1 mb-3"
                onClick={() => {
                  this.goHome();
                }}
              >
                {" "}
                Take New {this.props.location.state.exam}{" "}
              </Button>
              <br />
              <Button
                className="mr-1 mb-3"
                variant="outline-primary"
                onClick={() => {
                  this.goBack();
                }}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                className="ml-1 mb-3"
                onClick={() => {
                  this.goNext();
                }}
              >
                {" "}
                Next{" "}
              </Button>
            </header>
            <Card border={this.state.border} className="shadow-lg">
              <Card.Header>
                <Card.Subtitle>
                  <div className="mb-1">
                    Total Time Taken: {this.state.time}
                  </div>
                  <div className="mb-1">
                    {" "}
                    Total Score: {this.state.totalScore}
                  </div>
                  <div className="mb-1">
                    Your Answer:{" "}
                    {this.state.selectedOption === undefined ? (
                      <div> </div>
                    ) : (
                      <div className="inline">{this.state.selectedOption}</div>
                    )}
                  </div>
                  <div className="mb-1">
                    Correct Answer: {questionArray[questionIdInArray][6]}
                  </div>
                </Card.Subtitle>
              </Card.Header>
              <Card.Body className="results-question">
                <Card.Title>
                  Question {questionIdInArray + 1} of {questionArray.length}
                </Card.Title>
                <Card.Text>
                  <Dynamic html={this.state.question} />
                  <label>
                    <Dynamic html={this.state.ansA} />
                  </label>
                  <br></br>
                  <label>
                    <Dynamic html={this.state.ansB} />
                  </label>
                  <br></br>
                  <label value={this.state.ansC}>
                    <Dynamic html={this.state.ansC} />
                  </label>
                  <br></br>
                  <label>
                    <Dynamic html={this.state.ansD} />
                  </label>
                  <br></br>
                  <label>
                    <Dynamic html={this.state.ansE} />
                  </label>
                  {this.state.explanation ? (
                    <p>
                      Link to Explanation:{" "}
                      <a href={this.state.explanation}>
                        Click Here for Instructional Video!
                      </a>
                    </p>
                  ) : null}
                </Card.Text>
              </Card.Body>
              <Card.Footer className="results-solution">
                Professor Solution:
                <Dynamic html={this.state.solution} />
              </Card.Footer>
            </Card>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default Results;
