import React, { Component } from "react";
import Login from "./components/Login";
import { Switch, BrowserRouter } from "react-router-dom";
import Register from "./components/register";
import Questionpage from "./components/Questionpage";
import Maintainer from "./components/Maintainer";
import Results from "./components/Results";
import Profile from "./components/profile";

import PublicRoute from "./components/Routes/PublicRoute";
import PrivateRouteLogin from "./components/Routes/PrivateRouteLogin";
import PrivateRouteQP from "./components/Routes/PrivateRouteQuestionPage";
import PrivateRouteResults from "./components/Routes/PrivateRouteResults";
import PrivateRouteAdmin from "./components/Routes/PrivateRouteAdmin";
import Home from "./components/home";
import FAQ from "./components/faq";
import Contact from "./components/contact";
import Reset from "./components/reset";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute
            restricted={false}
            exact
            path={"/login"}
            component={Login}
          ></PublicRoute>
          <PublicRoute
            restricted={false}
            path={"/reset/:token"}
            component={Reset}
          ></PublicRoute>
          <PrivateRouteLogin
            exact
            path={"/"}
            component={Home}
          ></PrivateRouteLogin>
          <PrivateRouteLogin
            path={"/tests"}
            component={Home}
          ></PrivateRouteLogin>
          <PublicRoute
            restricted={false}
            exact
            path={"/register"}
            component={Register}
          ></PublicRoute>
          <PrivateRouteQP
            exact
            path={"/question_page"}
            component={Questionpage}
          ></PrivateRouteQP>
          <PrivateRouteAdmin
            exact
            path={"/maintainer"}
            component={Maintainer}
          ></PrivateRouteAdmin>
          <PrivateRouteLogin
            exact
            component={Profile}
            path={"/account-info"}
          ></PrivateRouteLogin>
          <PrivateRouteLogin
            exact
            component={FAQ}
            path={"/faq"}
          ></PrivateRouteLogin>
          <PrivateRouteLogin
            exact
            component={Contact}
            path={"/contact"}
          ></PrivateRouteLogin>
          <PrivateRouteResults
            exact
            path={"/results"}
            component={Results}
          ></PrivateRouteResults>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
