import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import MenuBook from "@material-ui/icons/MenuBook";
import Publish from "@material-ui/icons/Publish";
import Collapse from "@material-ui/core/Collapse";
import getData from "./functions/getData";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (userData === "") {
      getData().then((x) => {
        if (x.isLoaded) {
          setUserData(x.data);
        }
      });
    }
  }, [userData]);

  function toggleCollapse() {
    setCollapsed((prevValue) => !prevValue);
  }
  let expandIcon = !collapsed ? (
    <ExpandLess
      className={
        "sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"
      }
    />
  ) : (
    <ExpandMore className="sidebar-item-expand-arrow" />
  );
  return (
    <div className="sidebar">
      <List dense="true" disablePadding>
        {userData.is_maintainer === 1 ? (
          <ListItem component={Link} to="/maintainer" button>
            <Publish fontSize="small" className="sidebar-item-icon" />
            <ListItemText>Upload</ListItemText>
          </ListItem>
        ) : null}
        <ListItem onClick={toggleCollapse} button>
          <MenuBook fontSize="small" className="sidebar-item-icon" />
          <ListItemText>Tests</ListItemText>
          {expandIcon}
        </ListItem>
        <Collapse in={!collapsed}>
          <List disablePadding dense="true">
            <div style={{ paddingLeft: 10 }}>
              <ListItem component={Link} to="/tests/p-exam" button>
                <ListItemText dense="true" className="sidebar-item-text">
                  Probability Exam
                </ListItemText>
              </ListItem>
              <Tooltip title="Coming Eventually" placement="right">
                <ListItem component={Link} button>
                  <ListItemText dense="true" className="sidebar-item-text">
                    Financial Mathematics Exam
                  </ListItemText>
                </ListItem>
              </Tooltip>
            </div>
          </List>
        </Collapse>
      </List>
    </div>
  );
}
