import React from "react";
import MainNav from "./MainNav";
import { Card, Image, Row, Col } from "react-bootstrap";
import img from "./assets/i-6mXxTf7-XL.jpg";
export default function Contact() {
  return (
    <div className="home">
      <MainNav />
      <div className="faq">
        <h1 className="display-4">Contact Info</h1>
        <hr />
        <Card>
          <div className="contact shadow">
            <Row>
              <Col className="mt-3 mb-3 ml-3" md={6}>
                <Image className="" src={img} thumbnail />
              </Col>
              <Col>
                <div className="mt-4 mb-3 vertical"></div>
              </Col>
              <Col md={5} className="mt-4 mb-3 mr-5">
                <p>
                  <strong>
                    Dr. Jesse Johnson is an associate professor of mathematics
                    at Westfield State University in Westfield, Massachusetts.
                    He teaches a variety of courses in foundations, mathematics
                    education, mathematical literature, statistics, and
                    actuarial sciences. He also serves as the director of the{" "}
                    <a
                      target="_blank"
                      href="http://www.westfield.ma.edu/math/MathWeb/Actuarial/Actuarial.html"
                    >
                      Westfield Actuarial Sciences Program.
                    </a>
                  </strong>
                </p>
                <p>
                  <strong>
                    Dr. Johnson is the creator and editor of the content on
                    OpenActuary.org. If you have comments, questions, or
                    concerns regarding OpenActuary.org, or if you are a
                    potential student interested in learning about the actuarial
                    sciences at Westfield State University, please feel free to
                    contact him. Dr. Johnson also provides workshops related to
                    creating or improving actuarial sciences programs. Please
                    contact him for more information.
                  </strong>
                </p>
                <p>
                  <strong>Email: jwjohnson@westfield.ma.edu</strong>
                </p>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
}
