import React from "react";
import { Badge } from "react-bootstrap";
import Axios from "axios";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "checkbox",
      checked: this.props.checked,
      onChange: this.props.onChange,
      disabled: this.props.disabled,
      difficulty: this.props.difficulty,
      number: 0,
    };
  }
 
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  }
 
  render() {
    return (
      <div>
        <label>
          {"   "}
          <input
            disabled={this.state.disabled}
            type={this.state.type}
            name={this.props.name}
            checked={this.state.checked}
            onChange={this.state.onChange}
          />{" "}
          {this.props.name}
        </label>{" "}
        <Badge variant="primary">{this.props.count}</Badge>
      </div>
    );
  }
}

export default Checkbox;
