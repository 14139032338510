import React from "react";
import MainNav from "./MainNav";
import { Card, Accordion } from "react-bootstrap";
import data from "./data/faq.json";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const faqList = data.questions;

export default function FAQ() {
  return (
    <div className="home">
      <MainNav />
      <div className="faq ">
        <Card
          className="shadow"
          style={{ height: "12rem", backgroundColor: "#8acbff" }}
        >
          <h3 className="mt-2">
            <strong>Frequently Asked Questions</strong>
          </h3>
          <p className="mt-3 lead">
            Got a question? We're here to answer. Below are some of the most
            frequently asked questions to help you out!
          </p>
        </Card>
        <div className="questions">
            {faqList.map((q, index) => (
              <Accordion  className="text-left shadow">
              <Card>
                <Accordion.Toggle style={{cursor: 'pointer'}} as={Card.Header} eventKey={index}>
                  {q.question}
                  <div className="text-right">
                    <ExpandMoreIcon className="sidebar-item-expand-arrow" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body>{q.answer} </Card.Body>
                </Accordion.Collapse>
              </Card>
              </Accordion>
            ))}
          <br />
        </div>
      </div>
    </div>
  );
}
