import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import MainNav from "./MainNav";
import getData from "./functions/getData";
import QuestionAnswerOutlined from "@material-ui/icons/QuestionAnswerOutlined";
import ImportContactsOutlined from "@material-ui/icons/ImportContactsOutlined";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import AssessmentOutlined from "@material-ui/icons/AssessmentOutlined";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
import BallotOutlined from "@material-ui/icons/BallotOutlined";
import { Jumbotron, Card } from "react-bootstrap";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import QuestionsParameters from "./QuestionsParameters";

export default function Home(props) {
  let { path } = useRouteMatch();
  const [userData, setUserData] = useState("");
  const routes = [
    {
      name: "Probability Exam",
      path: "p-exam",
      book: "PExamBook",
      database: "questions",
      resultDB: "results",
      tagScoreDB: "tagscores",
    },
  ];
  useEffect(() => {
    localStorage.removeItem("question_page_token");
    localStorage.removeItem("param_token");
    localStorage.removeItem("result_has_run");
    localStorage.removeItem("tag_has_run");
    localStorage.removeItem("time");
    if (userData.is_maintainer === 1) {
      localStorage.setItem("admin_token", true);
    }
    if (userData === "") {
      getData().then((x) => {
        if (x.isLoaded) {
          setUserData(x.data);
        }
      });
    }
  }, [userData]);

  return (
    <div className="home">
      <MainNav />
      <Sidebar />
      {path === "/" ? (
        <div className="landing-page">
          <Jumbotron className="title" fluid>
            <h1 className="display-4">Welcome to Open Actuary</h1>
            <h5>A place to prepare for the Actuarial Certification Exams</h5>
          </Jumbotron>
          <div className="cards">
            <Card bg="primary" className="card shadow-lg">
              <Card.Body>
                <QuestionAnswerOutlined fontSize="large" />
                <Card.Title>Over 1000 questions to choose from.</Card.Title>
                <Card.Text>
                  Test your knowledge from the wide assortment of questions
                  provided by Professor Jesse Johnson.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card bg="light" className="card shadow">
              <Card.Body>
                <ImportContactsOutlined fontSize="large" />
                <Card.Title>Textbook's provided for each exam.</Card.Title>
                <Card.Text>
                  Obtain a PDF of the textbook's associated with each test.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card bg="primary" className="card shadow">
              <Card.Body>
                <BallotOutlined fontSize="large" />
                <Card.Title>Be able to take custom quizzes.</Card.Title>
                <Card.Text>
                  Pick and choose how you wanted to be tested by picking the
                  subjects, the amount of questions, and the difficulty.
                </Card.Text>
              </Card.Body>
            </Card>
            <div className="break"></div>
            <Card bg="light" className="card shadow">
              <Card.Body>
                <DescriptionOutlined fontSize="large" />
                <Card.Title>
                  Take exam's that are curated from a specified distribution.
                </Card.Title>
                <Card.Text>
                  Each exam you take will be 30 questions, 3-hours long, and is
                  randomized from a specified test distribution.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card bg="primary" className="card shadow">
              <Card.Body>
                <CheckBoxOutlined fontSize="large" />
                <Card.Title>Recieve solutions after exam or quiz.</Card.Title>
                <Card.Text>
                  Each question you are tested on will have a solution you can
                  study. Whether it be an instructional video or a written
                  solution.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card bg="light" className="card shadow">
              <Card.Body>
                <AssessmentOutlined fontSize="large" />
                <Card.Title>
                  Be able to see a history of test's you've taken.
                </Card.Title>
                <Card.Text>
                  You will be able to see how you've done on past exam or
                  quizzes to measure growth.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : null}

      <div>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={`/tests/${route.path}`}
              children={
                <QuestionsParameters
                  title={route.name}
                  book={route.book}
                  path={route.path}
                  history={props.history}
                  database={route.database}
                  resultDB={route.resultDB}
                  tagScoreDB={route.tagScoreDB}
                />
              }
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}
