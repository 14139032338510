import React, { Component } from "react";
import { Button, Alert, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";

import ForgotPass from "./forgotPass";

const initialState = {
  errorMessage: "",
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      userData: {},
      reset: false,
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.setState(initialState);
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        reset: true,
        message: this.props.location.state.message,
      });
    }
  }

  googleSignIn(response) {
    const payload = {
      fName: response.profileObj.givenName,
      lName: response.profileObj.familyName,
      email: response.profileObj.email,
      googleId: response.profileObj.googleId,
    };
    axios
      .post(`https://app.openactuary.org/api/googleLogin`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          console.log(response);
          localStorage.setItem("user_token", response.data.token);
          this.props.history.push("/");
        } else {
          console.log(response);
        }
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    let errorMessage = "";
    let payload = {
      username: this.state.username,
      password: this.state.password,
    };
    if (this.state.username.length) {
      axios
        .post(`https://app.openactuary.org/api/login`, payload)
        .then((response) => {
          if (response.data.code === 200) {
            localStorage.setItem("user_token", response.data.token);
            this.props.history.push("/");
          } else if (response.data.code === 204) {
            errorMessage = response.data.failure;
            this.setState({ errorMessage });
          } else {
            errorMessage = response.data.failure;
            this.setState({ errorMessage });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    const responseGoogle = (response) => {
      console.log(response.profileObj);
      this.googleSignIn(response);
    };
    const failedGoogle = (response) => {
      console.log(response);
    };
    return (
      <div className="sign-in">
        <div className="form-signin">
          <Card
            className="shadow"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
            }}
          >
            <div className="form">
              <span style={{ fontSize: 50, color: "#2196f3" }}>Open </span>
              <strong style={{ fontSize: 50, color: "#064579" }}>
                Actuary
              </strong>
              <h2 className="text-center font-weight-normal">
                Log in or Register
              </h2>
              <Alert show={this.state.reset} variant="success">
                <Button
                  variant="link"
                  onClick={() =>
                    this.setState({
                      reset: false,
                    })
                  }
                  className="close nounderline"
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
                <p>{this.state.message}</p>
              </Alert>
              <form onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="User Name"
                  value={this.state.username}
                  onChange={this.handleChange}
                  required
                  autoFocus
                />
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
                {this.state.errorMessage ? (
                  <div
                    style={{ fontSize: 12, fontWeight: "bold", color: "red" }}
                  >
                    {this.state.errorMessage}
                  </div>
                ) : null}
                <Button
                  className="mt-3 mb-2 shadow"
                  variant="primary"
                  size="lg"
                  block
                  type="submit"
                >
                  <i className="fas fa-sign-in-alt"></i>
                  Login
                </Button>
              </form>
              <GoogleLogin
                className="btn-block center shadow"
                clientId={process.env.REACT_APP_CLIENT_ID}
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={failedGoogle}
                cookiePolicy={"single_host_origin"}
              />
              <ForgotPass />
              <h5 className="mt-2 text-center font-weight-bolder">
                New Account?
                <Link
                  className="nounderline btn btn-lg btn-link btn-block"
                  to="/register"
                >
                  Register
                </Link>
              </h5>
            </div>
          </Card>
          <br />
        </div>
      </div>
    );
  }
}

export default Login;
