import React, { Component } from "react";
import { Button, Card, Alert, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Axios from "axios";
export default class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      confirmPass: "",
      error: false,
      validated: false,
      message: "",
      show: false,
      type: "",
    };
    this.updatePassword = this.updatePassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    await Axios.get(`https://app.openactuary.org/api/reset`, {
      params: { reset_password_token: this.props.match.params.token },
    }).then((response) => {
      if (response.data.code === 200) {
        this.setState({
          username: response.data.username,
          error: false,
        });
      } else if (response.data.code === 401) {
        this.setState({
          error: true,
        });
      }
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  updatePassword(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (this.state.password.length < 8) {
        this.setState({
          message: "Password must be at least 8 characters long",
          type: "danger",
          show: true,
        });
      } else if (this.state.confirmPass !== this.state.password) {
        this.setState({
          message: "Password Confirmation doesn't match Password",
          type: "danger",
          show: true,
        });
      } else {
        Axios.put(`https://app.openactuary.org/api/updatePasswordViaEmail`, {
          username: this.state.username,
          password: this.state.password,
          reset_password_token: this.props.match.params.token,
        }).then((response) => {
          if (response.data.code === 200) {
            this.props.history.push({
              pathname: "/login",
              state: { message: response.data.message, reset: true },
            });
            this.setState({
              error: false,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        });
      }
    }
    this.setState({
      validated: true,
    });
    event.preventDefault();
  }
  render() {
    const { password, confirmPass, error, validated } = this.state;
    if (error) {
      return (
        <div className="resetPage">
          <span style={{ fontSize: 40, color: "#2196f3" }}>Open </span>
          <strong style={{ fontSize: 40, color: "#064579" }}>Actuary</strong>
          <div className="reset">
            <h4>Problem resetting password. Please send another reset link</h4>
            <Link className="mt-4 btn btn-primary" to="/login">
              Back to Login
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="resetPage">
          <span style={{ fontSize: 40, color: "#2196f3" }}>Open </span>
          <strong style={{ fontSize: 40, color: "#064579" }}>Actuary</strong>
          <div className="reset">
            <Card className="shadow" style={{ height: "30rem" }}>
              <h3 className="mt-2">
                <strong>Password Reset</strong>
              </h3>
              <div className="reset-form">
                <Alert show={this.state.show} variant={this.state.type}>
                  <Button
                    variant="link"
                    onClick={() =>
                      this.setState({
                        show: false,
                      })
                    }
                    className="close nounderline"
                  >
                    <span aria-hidden="true">&times;</span>
                  </Button>
                  <p>{this.state.message}</p>
                </Alert>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={this.updatePassword}
                >
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      name="password"
                      onChange={(e) => this.handleChange(e)}
                      value={password}
                      required
                      type="password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name="confirmPass"
                      onChange={(e) => this.handleChange(e)}
                      value={confirmPass}
                      required
                      type="password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a confirmation password.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button type="submit">Change Password</Button>
                </Form>
              </div>
            </Card>
          </div>
        </div>
      );
    }
  }
}
