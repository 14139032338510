import Axios from "axios";

async function getData() {
  let data = null;
  let isLoaded = false;
  const token = localStorage.getItem("user_token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await Axios.post(
    `https://app.openactuary.org/api/data`,
    { a: "placeholder" },
    config
  ).then((response) => {
    data = response.data.authData.userData;
    if (data != null) isLoaded = true;
  });
  return { data, isLoaded };
}

export default getData;
