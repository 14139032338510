import React from "react";
import Checkbox from "./Checkbox";
import Axios from "axios";

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: this.props.checkedItems,
      categories: [],
      disabled: this.props.disabled,
    };
    this.getCat = this.getCat.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getCat();
  }
  getCat() {
    const payload = {
      database: this.props.database,
    };
    const {difficulty} = this.props;
    const getNumsPayLoad = {
      ...payload,
      difficulty
    }
    Axios.all([
      Axios.post(`https://app.openactuary.org/api/getCats`, payload), 
      Axios.post(`https://app.openactuary.org/api/getAllNums`, getNumsPayLoad)
    ])
    .then(Axios.spread((categories, numbers) => {
      let catMap = categories.data.catMap;
      const catMapCode = categories.data.code;
      const catNum = numbers.data.catNum;
      let catNumCode = numbers.data.code;
      if(catMapCode === 200 && catNumCode === 200) {
        this.setNumbers(catMap, catNum)     
      }
      else {
        console.log("error occured");
      }
      
    }))
    .catch(error => {
      console.log(error);
    })
  }
  componentDidUpdate(prevProps) {
     if (prevProps.difficulty !== this.props.difficulty) {
      this.getNumbers();
    }
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  }
   getNumbers() {
    const payload = {
      difficulty: this.props.difficulty,
      database: this.props.database,
    };
    Axios.post(`https://app.openactuary.org/api/getAllNums`, payload).then(
      (response) => {
        if (response.data.code === 400) {
        } else {
          const {catNum} = response.data;
          let { categories } = this.state
          this.setNumbers(categories, catNum)
           
        }
      }
    );
  }
  setNumbers(categories, catNum) {  
     categories = categories.map(cat => {
       const mapped =  catNum.find(catCount => catCount.name === cat.name )
       if(mapped) {
        cat["count"] = mapped.tagsCount
       }
       else {
        cat["count"] = 0
       }
       return cat;
    })
    this.setState({
      categories
    });
  }
  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  }

  render() {
    return (
      <React.Fragment>
        {this.state.categories.map((item, key) => (
          <Checkbox
            database={this.props.database}
            difficulty={this.props.difficulty}
            disabled={this.state.disabled}
            key={key}
            name={item.name}
            count={item.count}
            checked={this.state.checkedItems.get(item.name)}
            onChange={this.handleChange}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;
