import React from "react";
import CheckboxContainer from "./parameters/CheckboxContainer";
import { Button, Tabs, Tab, Card, Table } from "react-bootstrap";
import axios from "axios";

import getData from "./functions/getData";

const initialState = {
  errorMessage: null,
  catMessage: null,
};

class QuestionsParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quesArray: [],
      numOfQues: 0,
      difficulty: "('Very easy', 'Easy', 'Medium')",
      regexp: /^[0-9\b]+$/,
      categories: new Map(),
      userData: new Map(),
      saved: false,
      results: [],
      tags: [],
      isLoaded: false,
    };

    this.onRadioChange = this.onRadioChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getExam = this.getExam.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.undo = this.undo.bind(this);
    this.deleteHistory = this.deleteHistory.bind(this);
  }

  undo() {
    this.setState({ saved: false });
  }
  getExam(type) {
    const newCat = this.state.categories;
    const catArray = [];
    let catString = "";
    for (const [key, value] of newCat.entries(newCat)) {
      if (value === true) {
        catArray.push("'" + key + "'");
      }
    }

    catString = "(" + catArray.toString() + ")";

    const payload = {
      tags: catString,
      difficulty: this.state.difficulty,
      limit: this.state.numOfQues,
      type: type,
      database: this.props.database,
    };

    axios
      .post(`https://app.openactuary.org/api/getQuestions`, payload)
      .then((response) => {
        this.setState({
          quesArray: response.data.question2DArray,
        });
        localStorage.setItem("param_token", true);
        this.props.history.push({
          pathname: "/question_page",
          state: {
            userData: this.state.userData,
            type: type,
            path: this.props.path,
            resultDB: this.props.resultDB,
            tagScoreDB: this.props.tagScoreDB,
            exam: this.props.title,
            questionArray: this.state.quesArray,
          },
        });
      });
  }
  //Form of examination
  onRadioChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  //Difficulty: changing state
  changeDifficulty = (event) => {
    let diff;
    if (event.target.value === "Level 1") {
      diff = "('Very easy', 'Easy', 'Medium')";
    } else if (event.target.value === "Level 2") {
      diff = "('Easy', 'Medium', 'Hard')";
    } else if (event.target.value === "Level 3") {
      diff = "('Medium', 'Hard', 'Very hard')";
    }
    this.setState({
      difficulty: diff,
    });
  };
  //Number of questions: handle input
  handleInputChange(e) {
    e.preventDefault();
    let numOfQues = e.target.value;
    if (numOfQues === "" || this.state.regexp.test(numOfQues)) {
      this.setState({
        [e.target.name]: parseInt(e.target.value),
      });
    }
  }

  //Validate Parameters
  validate = () => {
    let errorMessage = null;
    let catMessage = null;
    if (!this.state.numOfQues) {
      errorMessage = "Please type a number";
    }
    let checkCats = false;
    this.state.categories.forEach((v) => {
      if (v === true) {
        checkCats = v;
      }
    });
    if (checkCats === false || this.state.categories.size === 0) {
      catMessage = "Please pick at least 1 category";
    }

    if (errorMessage || catMessage) {
      this.setState({ errorMessage, catMessage });
      return false;
    }
    return true;
  };

  componentWillMount() {
    localStorage.removeItem("question_page_token");
    localStorage.removeItem("param_token");
    localStorage.removeItem("result_has_run");
    localStorage.removeItem("tag_has_run");
    localStorage.removeItem("time");

    getData().then((x) => {
      if (x.isLoaded) {
        this.setState({
          userData: x.data,
        });
      }
    });
  }
  componentDidUpdate() {
    if (this.state.userData.size !== 0 && this.state.isLoaded == false) {
      this.getResults();
      this.setState({
        isLoaded: true,
      });
    }
  }
  componentDidMount() {}
  //
  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      //clear form
      this.setState(initialState);
      this.setState({ saved: true });
    } else {
      this.setState({ saved: false });
    }
  };
  getResults() {
    const payload = {
      userId: this.state.userData.user_id,
      resultDB: this.props.resultDB,
      tagScoreDB: this.props.tagScoreDB,
    };
    let descriptor;
    let newTagArray = [];

    axios
      .post(`https://app.openactuary.org/api/getRes`, payload)
      .then((response) => {
        if (response.data.code === 400) {
          console.log(response.data.error);
        } else {
          let tagObj = response.data.tagVal;
          for (let tag in tagObj) {
            descriptor = Object.getOwnPropertyDescriptor(tagObj, tag);
            newTagArray.push({
              name: tag,
              value: descriptor.value,
            });
          }
          this.setState({
            results: response.data.resultData,
            tags: newTagArray,
          });
        }
      });
  }
  deleteHistory() {
    const payload = {
      userId: this.state.userData.user_id,
      resultDB: this.props.resultDB,
      tagScoreDB: this.props.tagScoreDB,
    };
    axios
      .post(`https://app.openactuary.org/api/deleteHistory`, payload)
      .then((response) => {
        if (response.data.code === 400) {
          console.log(response.data.failed);
        } else if (response.data.code === 500) {
          console.log(response.data.error);
        } else if (response.data.code === 200) {
          console.log(response.data.success);
          this.getResults();
        }
      });
  }
  render() {
    return (
      <div className="questionParam" style={{ paddingBottom: "50px" }}>
        <h1 className="text-left">{this.props.title}</h1>
        <div className="tabs">
          <Tabs defaultActiveKey="quiz" className="col-lg-12 mb-2 mt-3">
            <Tab eventKey="textbook" title="Textbook">
              <p>
                Please click on the following link to navigate to a PDF of the{" "}
                {this.props.title} Textbook:
              </p>
              <a
                target="_blank"
                className="shadow nounderline btn-primary  btn-lg"
                href={`https://app.openactuary.org/api/textbook/${this.props.book}.pdf`}
                download
              >
                View Textbook
              </a>
            </Tab>
            <Tab eventKey="quiz" title="Take A Quiz">
              <div className="options">
                <div className="mainCat">
                  <p style={textStyle}>Select Categories: </p>
                  <hr />
                  {this.state.catMessage ? (
                    <div
                      style={{ fontSize: 12, fontWeight: "bold", color: "red" }}
                    >
                      {this.state.catMessage}
                    </div>
                  ) : null}
                  <br />
                  <div className="categories">
                    <CheckboxContainer
                      difficulty={this.state.difficulty}
                      disabled={this.state.saved}
                      checkedItems={this.state.categories}
                      database={this.props.database}
                    />
                  </div>
                </div>

                <div className="other">
                  <p style={textStyle}>Select Difficulty and Amount: </p>
                  <hr />
                  <p>Number of questions?</p>
                  <input
                    type="number"
                    min="1"
                    className=" mb-2 form-control form-control-small"
                    disabled={this.state.saved}
                    name="numOfQues"
                    onChange={this.handleInputChange}
                  ></input>
                  {this.state.errorMessage ? (
                    <div
                      style={{ fontSize: 12, fontWeight: "bold", color: "red" }}
                    >
                      {this.state.errorMessage}
                    </div>
                  ) : null}
                  <br />
                  <p>Difficulty?</p>
                  <select
                    name="difficultyLevel"
                    disabled={this.state.saved}
                    className="form-control form-control-small"
                    onChange={this.changeDifficulty}
                  >
                    <option defaultValue="Level 1">Level 1</option>
                    <option value="Level 2">Level 2</option>
                    <option value="Level 3">Level 3</option>
                  </select>
                  <br />
                  <br />
                  <Button onClick={this.handleSubmit} className="mt-2">
                    Save Selections
                  </Button>
                  <br />
                  <Button
                    disabled={!this.state.saved}
                    onClick={() => this.getExam("quiz")}
                    className="mt-2"
                  >
                    Start
                  </Button>
                  <br />
                  <br />
                  {this.state.saved ? (
                    <Button
                      onClick={this.undo}
                      className="mt-2"
                      variant="danger"
                    >
                      Undo
                    </Button>
                  ) : null}
                </div>
              </div>
            </Tab>

            <Tab className="mb-3" eventKey="exam" title="Take An Exam">
              <p>
                This exam contains 30 questions. You have 3 hours to finish the
                exam.
              </p>
              <Button className="shadow" onClick={() => this.getExam("exam")}>
                Start
              </Button>
            </Tab>
            <Tab eventKey="history" title="Test History">
              <br />
              <div className="ml-5 text-left">
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete your history?"
                      )
                    ) {
                      this.deleteHistory();
                    }
                  }}
                  variant="danger"
                >
                  Delete Test History
                </Button>
              </div>
              <br />
              <div className="results">
                <Card className="col-lg-5 shadow">
                  <Card.Body>
                    <Card.Title>Test History</Card.Title>
                    <Card.Text>
                      <Table size="sm" striped bordered hover>
                        <thead>
                          <tr>
                            <th>Test ID</th>
                            <th>Test Score</th>
                            <th>Time Spent</th>
                            <th>Test Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.results.map((item, key) => (
                            <tr key={key}>
                              <td>{item.idresults}</td>
                              <td>{item.resultPercentage}</td>
                              <td>{item.timeSpent}</td>
                              <td>{item.testType}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="col-lg-5 shadow">
                  <Card.Body>
                    <Card.Title>Category Progression</Card.Title>
                    <Card.Text>
                      <Table size="sm" striped bordered hover>
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Category Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tags.map((item, key) => (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>{item.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <br />
            </Tab>
          </Tabs>
        </div>
        <br />
      </div>
    );
  }
}

const textStyle = {
  fontSize: "22px",
  fontWeight: "bold",
  color: "black",
};

export default QuestionsParameters;
