import React, { Component } from "react";
import MathJax from "react-mathjax2";
class Dynamic extends Component {
  render() {
    return (
      <MathJax.Context
        className="inline"
        input="ascii"
        onLoad={() => console.log("Loaded MathJax script!")}
        onError={(MathJax, error) => {
          console.warn(error);
          console.log("Encountered a MathJax error, re-attempting a typeset!");
          MathJax.Hub.Queue(MathJax.Hub.Typeset());
        }}
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=TeX-AMS_HTML-full"
      >
        <MathJax.Text
          text={
            <span
              className="font-weight-normal"
              dangerouslySetInnerHTML={{ __html: this.props.html }}
            ></span>
          }
        />
      </MathJax.Context>
    );
  }
}

export default Dynamic;
