import React, { useEffect, useState } from "react";
import Axios from "axios";
import getData from "./functions/getData";
import { Button, Card, Form, Alert } from "react-bootstrap";
import MainNav from "./MainNav";

export default function Profile() {
  const [userData, setUserData] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [username, setUsername] = useState("");
  const [school, setSchool] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");

  function changePass() {
    const payload = {
      userId: userData.user_id,
      corrPass: userData.password,
      newPass: password,
      oldPass: oldPass,
    };
    if (payload.newPass.length < 8) {
      setMessage("Password must be at least 8 characters long");
      setType("danger");
      setShow(true);
      window.scrollTo(0, 0);
    } else if (confirmPass !== payload.newPass) {
      setMessage("Password Confirmation doesn't match Password");
      setType("danger");
      setShow(true);
      window.scrollTo(0, 0);
    } else {
      Axios.post(`https://app.openactuary.org/api/changePassword`, payload).then(
        (response) => {
          if (response.data.code === 400) {
            console.log(response.data.failed);
          } else if (response.data.code === 401) {
            setMessage(response.data.failed);
            setType("danger");
            setShow(true);
            window.scrollTo(0, 0);
          } else if (response.data.code === 200) {
            setMessage(response.data.success);
            setType("success");
            setShow(true);
            window.scrollTo(0, 0);
            localStorage.removeItem("user_token");
            localStorage.setItem("user_token", response.data.token);
            getData(localStorage.getItem("user_token")).then((x) => {
              if (x.isLoaded) {
                setUserData(x.data);
              }
            });
          }
        }
      );
    }
  }
  function changeUserInfo(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const payload = {
        fName,
        lName,
        username,
        school,
        email,
        userId: userData.user_id,
      };
      Axios.post(`https://app.openactuary.org/api/changeUser`, payload).then(
        (response) => {
          if (response.data.code === 400) {
            console.log(response.data.failed);
          } else if (response.data.code === 401) {
            setType("danger");
            setMessage(response.data.failed);
            setShow(true);
            window.scrollTo(0, 0);
          } else if (response.data.code === 200) {
            setType("success");
            setMessage(response.data.success);
            setShow(true);
            window.scrollTo(0, 0);
            localStorage.setItem("user_token", response.data.token);
            getData(localStorage.getItem("user_token")).then((x) => {
              if (x.isLoaded) {
                setUserData(x.data);
              }
            });
          }
        }
      );
    }
    setValidated(true);
    event.preventDefault();
  }
  useEffect(() => {
    localStorage.removeItem("time");
    localStorage.removeItem("param_token");
    localStorage.removeItem("question_page_token");
    if (userData === "") {
      getData().then((x) => {
        if (x.isLoaded) {
          setUserData(x.data);
        }
      });
    }
    setEmail(userData.email);
    setFName(userData.f_name);
    setLName(userData.l_name);
    setUsername(userData.username);
    setSchool(userData.school);
  }, [userData]);

  return (
    <div>
      <div className="home">
        <MainNav />
        <div className="profile">
          <h2>Account Info</h2>
          <div>
            <Card className="col-lg-12">
              <Card.Body>
                <Card.Text as="span">
                  <h4> Change User Info </h4>

                  <Alert show={show} variant={type}>
                    <Button
                      variant="link"
                      onClick={() => setShow(false)}
                      className="close nounderline"
                    >
                      <span aria-hidden="true">&times;</span>
                    </Button>
                    <p>{message}</p>
                  </Alert>

                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={changeUserInfo}
                  >
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        onChange={(e) => setFName(e.target.value)}
                        value={fName}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        onChange={(e) => setLName(e.target.value)}
                        value={lName}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        disabled={userData.oauth_id}
                        required={!userData.oauth_id}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        Please choose a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        disabled={userData.oauth_id}
                        required={!userData.oauth_id}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose a username.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>School</Form.Label>
                      <Form.Control
                        required
                        onChange={(e) => setSchool(e.target.value)}
                        value={school}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid school.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Button type="submit" className="mb-2" variant="success">
                      Save Changes
                    </Button>
                  </Form>
                  <br />
                  <h4> Change Password </h4>
                  <Form>
                    <Form.Group>
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        disabled={userData.oauth_id}
                        required
                        onChange={(e) => setOldPass(e.target.value)}
                        type="password"
                      />
                      <Form.Text className="text-muted">
                        You must provide your current password in order to
                        change it.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        disabled={userData.oauth_id}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        disabled={userData.oauth_id}
                        required
                        onChange={(e) => setConfirmPass(e.target.value)}
                        type="password"
                      />
                    </Form.Group>
                    <br />
                    <Button
                      disabled={userData.oauth_id}
                      onClick={changePass}
                      className="mb-2 "
                      variant="success"
                    >
                      Submit
                    </Button>
                  </Form>
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
