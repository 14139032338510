import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import axios from "axios";
import MainNav from "./MainNav";
import Sidebar from "./sidebar";

class Maintainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionArray: null,
      imageURL: "",
      imagehtml: "",
      fileName: "",
      errorMessage: "",
      imgMessage: null,
      options: null,
      userData: "",
      choice: false,
      count: null,
    };
    this.uploadFiles = this.uploadFiles.bind(this);
    this.readFile = this.readFile.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.undo = this.undo.bind(this);
  }
  uploadFiles() {
    let arrayQues = this.state.questionArray;
    let payload = {};
    let tempCount = 0;

    if (this.state.options === "question") {
      for (let i = 0; i < arrayQues.length; i++) {
        arrayQues[i][1] = arrayQues[i][1].replace(/[\r\n]+/gm, " ");
        payload = {
          id: arrayQues[i][0],
          question: arrayQues[i][1],
          ansA: arrayQues[i][2],
          ansB: arrayQues[i][3],
          ansC: arrayQues[i][4],
          ansD: arrayQues[i][5],
          ansE: arrayQues[i][6],
          ans: arrayQues[i][7],
          diff: arrayQues[i][8],
          section: arrayQues[i][9],
          solution: arrayQues[i][10],
        };

        axios
          .post(`https://app.openactuary.org/api/insertQues`, payload)
          .then((response) => {
            if (response.data.code === 200) {
              tempCount++;
              this.setState({ count: tempCount });
            }
          })
          .catch(function (error) {
            console.log(error);
            this.setState({
              fileMessage: "Error occured",
            });
          });
      }
    } else {
      for (let i = 0; i < arrayQues.length; i++) {
        arrayQues[i][1] = arrayQues[i][1].replace(/[\r\n]+/gm, " ");
        payload = {
          questionID: arrayQues[i][0],
          solution: arrayQues[i][1],
        };
        axios
          .post(`https://app.openactuary.org/api/insertSol`, payload)
          .then((response) => {
            if (response.data.code === 200) {
              tempCount++;
              this.setState({ count: tempCount });
            }
          })
          .catch(function (error) {
            console.log(error);
            this.setState({
              errorMessage: "Error occured",
            });
          });
      }
    }
    this.uploadImage();
  }
  readFile(e) {
    const myParser = new DOMParser();
    let file = e.target.files[0];
    if (e.target.files[0] !== undefined) {
      const fileN = file.name.substring(0, file.name.indexOf(".html"));
      this.setState({
        fileName: fileN,
      });
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const lines = reader.result;
        const htmlObject = myParser.parseFromString(lines, "text/html");
        const images = htmlObject.getElementsByTagName("IMG");
        const tables = htmlObject.getElementsByTagName("TABLE");
        let tbody = "";
        for (let i of images) {
          const imageSrc = i.src;
          const imageID = imageSrc.substring(
            imageSrc.indexOf("image-") + 6,
            imageSrc.indexOf(".svg")
          );
          i.src = `https://app.openactuary.org/api/image/${imageID}/${this.state.fileName}`;
        }
        for (let t of tables) {
          t.className = "table table-striped table-bordered";
          tbody = t.children[0];
          for (let tr of tbody.children) {
            tr.className = "";
            for (let td of tr.children) {
              td.className = "";
              td.style = "";
            }
          }
        }
        let document = new Document();
        let myResult = document.evaluate(
          "/html/body/div/div/section/ul/li",
          htmlObject,
          null,
          XPathResult.ANY_TYPE,
          null
        );
        let singleQues = myResult.iterateNext();
        let arrayOfObj = [];

        while (singleQues) {
          if (this.state.options === "question") {
            let currElement;
            let htmlQues;
            let ansA;
            let ansB;
            let ansC;
            let ansD;
            let ansE;
            let ans;
            let diff;
            let section;
            let solution;
            singleQues.childNodes.forEach(function (element) {
              if (element.firstChild !== null) {
                if (element.nodeName === "P") {
                  if (element.textContent.includes("Ans:")) {
                    //Getting Answer and Difficulty
                    currElement = element;
                    let ansDifLine = currElement.textContent;
                    ans = ansDifLine.substring(
                      ansDifLine.indexOf(":") + 2,
                      ansDifLine.indexOf(".")
                    );
                    diff = ansDifLine.substring(
                      ansDifLine.lastIndexOf(":") + 2,
                      ansDifLine.length - 1
                    );
                    if (diff.includes(".")) {
                      diff = diff.slice(0, diff.length - 1);
                    }
                    //Getting Section

                    currElement = currElement.nextElementSibling;
                    if (currElement !== null) {
                      let sectionFull = currElement.textContent;
                      section = sectionFull.substring(
                        sectionFull.indexOf(":") + 2,
                        sectionFull.length - 1
                      );
                    } else {
                      section = null;
                    }
                  }
                  if (element.firstElementChild !== null) {
                    // Getting Solution link if there is one
                    if (element.firstElementChild.href !== null) {
                      solution = element.firstElementChild.href;
                    }
                  }
                } else if (element.nodeName === "UL") {
                  //Getting All Possible Answers
                  element.childNodes.forEach(function (item) {
                    if (item.nodeName === "LI") {
                      let listItem = item.firstElementChild.textContent;
                      if (listItem.includes("A. ")) {
                        ansA = listItem.substring(4, listItem.length - 1);
                      } else if (listItem.includes("B. ")) {
                        ansB = listItem.substring(4, listItem.length - 1);
                      } else if (listItem.includes("C. ")) {
                        ansC = listItem.substring(4, listItem.length - 1);
                      } else if (listItem.includes("D. ")) {
                        ansD = listItem.substring(4, listItem.length - 1);
                      } else if (listItem.includes("E. ")) {
                        ansE = listItem.substring(4, listItem.length - 1);
                      }
                    }
                  });
                }
              }
            });
            let html = singleQues.innerHTML;
            let num = parseInt(html.substring(5, html.indexOf(". ")));
            //Removing Question Number
            const fullHtmlQues = html.slice(0, html.indexOf("<p>\nA. ") - 41);
            let first = fullHtmlQues.substring(0, 4);
            let second = fullHtmlQues.substring(
              fullHtmlQues.indexOf(". ") + 2,
              fullHtmlQues.length
            );
            htmlQues = first + second;
            arrayOfObj.push([
              num,
              htmlQues,
              ansA,
              ansB,
              ansC,
              ansD,
              ansE,
              ans,
              diff,
              section,
              solution,
            ]);
          } else {
            let html = singleQues.innerHTML;
            let htmlSol;
            let num = parseInt(html.substring(5, html.indexOf(". ")));
            //Removing Question Number
            let first = html.substring(1, 4);
            let second = html.substring(html.indexOf(". ") + 2, html.length);
            htmlSol = first + second;
            arrayOfObj.push([num, htmlSol]);
          }
          singleQues = myResult.iterateNext();
        }
        this.setState({
          questionArray: arrayOfObj,
        });
      };
    }
  }
  undo() {
    const file = this.myFile;
    const images = this.myInput;

    file.value = "";
    images.value = "";
    this.setState({
      imgMessage: null,
      questionArray: null,
      fileName: "",
      options: null,
      choice: false,
      count: null,
    });
  }
  uploadImage() {
    const mySvgs = this.myInput;
    const formData = new FormData();
    let pastName = "";
    const count = mySvgs.files.length;
    for (let i = 0; i < count; i++) {
      pastName = mySvgs.files[i].name.substring(
        0,
        mySvgs.files[i].name.indexOf(".svg")
      );
      Object.defineProperty(mySvgs.files[i], "name", {
        writable: true,
        value: `${this.state.fileName}(${pastName}).svg`,
      });
      formData.append("photos", mySvgs.files[i], mySvgs.files[i].name);
    }
    axios
      .post(`https://app.openactuary.org/api/images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(function () {
        console.log("Failure");
      });
    this.setState({
      imgMessage: count + " image(s) submitted successfully",
    });
  }

  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      choice: true,
    });
  };

  componentWillMount() {
    localStorage.removeItem("time");
  }

  render() {
    return (
      <div className="home">
        <MainNav />
        <Sidebar />
        <div style={{ left: "250px" }} className="maintainer">
          <Card
            style={{
              height: "25rem",
            }}
          >
            <div className="form">
              <h2 className="text-left">Select Quiz File </h2>
              <div className="mb-2">
                <label className="mr-2">
                  <input
                    name="options"
                    value="question"
                    checked={this.state.options === "question"}
                    disabled={this.state.choice}
                    onChange={this.handleRadioChange}
                    type="radio"
                  />{" "}
                  Question File
                </label>
                <label>
                  <input
                    name="options"
                    disabled={this.state.choice}
                    value="solution"
                    checked={this.state.options === "solution"}
                    onChange={this.handleRadioChange}
                    type="radio"
                  />{" "}
                  Solution File
                </label>
                {this.state.choice ? (
                  <Button onClick={this.undo} className="ml-2" variant="danger">
                    Undo
                  </Button>
                ) : null}
              </div>
              <input
                disabled={!this.state.options}
                type="file"
                accept=".html"
                className="form-control"
                ref={(input) => {
                  this.myFile = input;
                }}
                onChange={(e) => this.readFile(e)}
              />

              <h2 className="text-left">Select Images </h2>
              <input
                disabled={!this.state.questionArray}
                type="file"
                accept=".svg"
                ref={(input) => {
                  this.myInput = input;
                }}
                className="form-control"
                multiple
              />

              <Button
                className="form-control mt-3"
                variant="primary"
                onClick={this.uploadFiles}
              >
                Submit
              </Button>
              {this.state.fileMessage === "Error occured" ? (
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {this.state.errorMessage}
                </div>
              ) : this.state.count !== null &&
                this.state.options === "question" ? (
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  {this.state.count + " question(s) submitted successfully"}
                </div>
              ) : this.state.count !== null &&
                this.state.options === "solution" ? (
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  {this.state.count + " solution(s) submitted successfully"}
                </div>
              ) : null}
              {this.state.imgMessage !== null ? (
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  {this.state.imgMessage}
                </div>
              ) : null}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
export default Maintainer;
