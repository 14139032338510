import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import Dynamic from "./display/Dynamic";
import MainNav from "./MainNav";
import Sidebar from "./sidebar";

class Questionpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionArray: this.props.location.state.questionArray,
      question: "",
      ansA: "",
      ansB: "",
      ansC: "",
      ansD: "",
      ansE: "",
      corrAnswers: "",
      selectedOption: "",
      questionIdInArray: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.displayQuestion = this.displayQuestion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleSetTimer = this.handleSetTimer.bind(this);
  }
  componentDidMount() {
    this.displayQuestion();
    this.handleSetTimer();
    this.myInterval = setInterval(() => {
      const { seconds, minutes, hours } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0 && minutes > 0 && hours > 0) {
        this.setState(({ minutes }) => ({
          minutes: minutes - 1,
          seconds: 59,
        }));
      }
      if (seconds === 0 && minutes === 0 && hours > 0) {
        this.setState(({ hours }) => ({
          hours: hours - 1,
          minutes: 59,
          seconds: 59,
        }));
      }
      if (seconds === 0 && minutes > 0 && hours === 0) {
        this.setState(({ hours }) => ({
          minutes: minutes - 1,
          seconds: 59,
        }));
      }

      if (seconds === 0 && minutes === 0 && hours === 0) {
        clearInterval(this.myInterval);
        this.handleSubmit();
      }
      window.addEventListener("unload", function (event) {
        localStorage.setItem("time", hours + ":" + minutes + ":" + seconds);
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  displayQuestion() {
    this.setState({
      question: this.state.questionArray[this.state.questionIdInArray][0],
      ansA: "A) " + this.state.questionArray[this.state.questionIdInArray][1],
      ansB: "B) " + this.state.questionArray[this.state.questionIdInArray][2],
      ansC: "C) " + this.state.questionArray[this.state.questionIdInArray][3],
      ansD: "D) " + this.state.questionArray[this.state.questionIdInArray][4],
      ansE: "E) " + this.state.questionArray[this.state.questionIdInArray][5],
      selectedOption: this.state.questionArray[
        this.state.questionIdInArray
      ][11],
    });
  }

  handleLoad(event) {
    this.displayQuestion();
  }

  //these methods change the value of the questionIdInArray state

  goBack() {
    if (this.state.questionIdInArray === 0) {
      this.setState({
        questionIdInArray: this.state.questionIdInArray,
      });
    } else {
      this.setState(
        {
          questionIdInArray: this.state.questionIdInArray - 1,
        },
        () => {
          this.handleLoad();
        }
      );
    }
  }

  goNext() {
    if (this.state.questionIdInArray === this.state.questionArray.length - 1) {
      this.setState({
        questionIdInArray: this.state.questionIdInArray,
      });
    } else {
      this.setState(
        {
          questionIdInArray: this.state.questionIdInArray + 1,
        },
        () => {
          this.handleLoad();
        }
      );
    }
  }

  handleSubmit = () => {
    localStorage.setItem("question_page_token", true);
    localStorage.removeItem("time");
    let time = this.state.questionArray.length * 6;
    let hr = 0;
    let min = 0;
    let sec = 0;
    while (time > 0) {
      if (time >= 60) {
        hr = hr + 1;
        time = time - 60;
      }
      if (time < 60) {
        min = time;
        time = time - min;
      }
    }
    if (hr - this.state.hours === 0) {
      hr = hr - this.state.hours;
    } else {
      hr = hr - this.state.hours - 1;
      min = 60;
    }

    if (min - this.state.minutes === 1) {
      min = 0;
      sec = 60;
    } else {
      min = min - this.state.minutes - 1;
      sec = 60;
    }

    if (sec - this.state.seconds === 60) {
      min = min + 1;
      sec = 0;
    } else {
      sec = sec - this.state.seconds;
    }
    this.setState(
      {
        hours: hr,
        minutes: min,
        seconds: sec,
      },
      () => {
        this.props.history.push({
          pathname: "/Results",
          state: {
            userData: this.props.location.state.userData,
            exam: this.props.location.state.exam,
            path: this.props.location.state.path,
            resultDB: this.props.location.state.resultDB,
            tagScoreDB: this.props.location.state.tagScoreDB,
            type: this.props.location.state.type,
            time:
              this.state.hours +
              ":" +
              this.state.minutes +
              ":" +
              this.state.seconds,
            quesArray: this.state.questionArray,
          },
        });
      }
    );
  };

  handleRadioChange = (changeEvent) => {
    this.setState(
      {
        selectedOption: changeEvent.target.value,
      },
      () => {
        let newArray = this.state.questionArray;
        let answers = this.state.selectedOption;
        newArray[this.state.questionIdInArray].push();
        newArray[this.state.questionIdInArray][11] = answers;

        this.setState({
          questionArray: newArray,
        });
      }
    );
  };

  handleSetTimer() {
    let time = this.state.questionArray.length;
    time = time * 6;
    let hr = 0;
    let min = 0;

    while (time > 0) {
      if (time >= 60) {
        hr = hr + 1;
        time = time - 60;
      }
      if (time < 60) {
        min = time;
        time = time - min;
      }
    }
    if (!localStorage.getItem("time")) {
      this.setState({
        hours: hr,
        minutes: min,
      });
    } else {
      const time = localStorage.getItem("time");
      const timeArray = time.split(":");
      const hour = timeArray[0];
      const minute = timeArray[1];
      const second = timeArray[2];
      this.setState({
        hours: hour,
        minutes: minute,
        seconds: second,
      });
    }
  }

  render() {
    const { hours, minutes, seconds } = this.state;
    return (
      <div className="home">
        <MainNav />
        <Sidebar />
        <div className="questionPage">
          <Card border="info" className="timer shadow">
            <Card.Title style={{ fontSize: "28px" }}>
              Time Remaining: {hours} : {minutes < 10 ? `0${minutes}` : minutes}{" "}
              : {seconds < 10 ? `0${seconds}` : seconds}{" "}
            </Card.Title>
          </Card>
          <div class="results-container">
            <header>
              <Button
                className="mr-1 mb-3"
                variant="outline-primary"
                onClick={() => {
                  this.goBack();
                }}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                className="ml-1 mb-3"
                onClick={() => {
                  this.goNext();
                }}
              >
                {" "}
                Next{" "}
              </Button>
            </header>
            <Card border="primary" className="shadow-lg">
              <Card.Header style={{ paddingTop: "1rem" }}>
                <Card.Subtitle>
                  Question {this.state.questionIdInArray + 1} of{" "}
                  {this.state.questionArray.length} {}
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <Dynamic className="question" html={this.state.question} />
                </Card.Title>
                <label className="radio">
                  <input
                    className="mb-3"
                    type="radio"
                    value="A"
                    style={{ width: "50px" }}
                    checked={this.state.selectedOption === "A"}
                    onChange={this.handleRadioChange}
                  />
                  <Dynamic className="inline" html={this.state.ansA} />
                </label>
                <br></br>
                <label className="radio">
                  <input
                    className="mb-3"
                    type="radio"
                    value="B"
                    style={{ width: "50px" }}
                    checked={this.state.selectedOption === "B"}
                    onChange={this.handleRadioChange}
                  />
                  <Dynamic html={this.state.ansB} />
                </label>
                <br></br>
                <label className="radio">
                  <input
                    className="mb-3"
                    type="radio"
                    value="C"
                    style={{ width: "50px" }}
                    checked={this.state.selectedOption === "C"}
                    onChange={this.handleRadioChange}
                  />
                  <Dynamic html={this.state.ansC} />
                </label>
                <br></br>
                <label className="radio">
                  <input
                    className="mb-3"
                    type="radio"
                    value="D"
                    style={{ width: "50px" }}
                    checked={this.state.selectedOption === "D"}
                    onChange={this.handleRadioChange}
                  />
                  <Dynamic html={this.state.ansD} />
                </label>
                <br></br>
                <label className="radio">
                  <input
                    className="mb-3"
                    type="radio"
                    value="E"
                    style={{ width: "50px" }}
                    checked={this.state.selectedOption === "E"}
                    onChange={this.handleRadioChange}
                  />
                  <Dynamic html={this.state.ansE} />
                </label>
              </Card.Body>
              <Card.Footer>
                <Button
                  className="mb-2 mt-2"
                  variant="success"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Submit Exam
                </Button>
              </Card.Footer>
            </Card>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default Questionpage;
